import {authHostelPosIam} from './firebase';
import firebase from "@firebase/app";

export const doSignInWithEmailAndPassword = (email, password, thenFunction, catchFunction) => {
    authHostelPosIam.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
            return authHostelPosIam.signInWithEmailAndPassword(email, password).then(thenFunction).catch(catchFunction);
        })
        .catch((error) => console.log(error.code, error.message));
};

export const signInAnonymously = () =>
    signInAnonymously();

export const doSignOut = () =>
    authHostelPosIam.signOut();