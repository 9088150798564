import React, {Component} from 'react';
import {BrowserRouter} from "react-router-dom";
import Routes from "./routes"
import {firestoreHostelPosIam, hostelPosUrl} from "./firebase";
import {snapshotToArray} from "./utils/"
import {spinLoader} from "./utils/loader";
import withAuthentication from "./session/withAuthentication";

/*
const HOSTELS_IDS = {
    "localhost": "BVCNRGTrpwrairlh0dvj",
    "hostel-pos-iam-213419.firebaseapp.com": "BVCNRGTrpwrairlh0dvj",
    "visitors.poolparadiselima.com": "BVCNRGTrpwrairlh0dvj",
    "visitors.huacachina.wildroverhostels.com": "TQC83gR1nXRQweo0kcm0",
    "visitors.arequipa.wildroverhostels.com": "i2feUzGUA3ikYIlU4LFs",
    "visitors.mancora.wildroverhostels.com": "DGO9ShEqUoA0I0EeLYre",
    "visitors.cusco.wildroverhostels.com": "Cozzt0nGbWZoHthjFSEU",
};
*/

class App extends Component {

    state = {
        registrationCode: "",
        guestCountry: "",
        users: [],
        hostels: [],
    };

    componentDidUpdate(prevProps) {
        if (this.props.currentUser && prevProps.currentUser === null) {
            this.fetchData();
        }
    };

    componentWillUnmount() {
        clearInterval(this.fetchData);
    }

    fetchData = () => {
        this.fetchUsers();
        this.fetchHostels();
    };

    fetchUsers = () => {
        firestoreHostelPosIam
            .collection("users")
            .onSnapshot(snapshot => this.setState({users: snapshotToArray(snapshot)}));
    };

    fetchHostels = async () => {
        const response = await this.fetchHostelsFromApi();
        const hostels = (await response.json()).hostels;
        this.setState({hostels: hostels});
    };

    fetchHostelsFromApi = async _ => await fetch(`${hostelPosUrl}/hostels`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });

    setGuestCountry = (guestCountry) => this.setState({guestCountry});

    setCurrentHostel = (hostel) => this.setState({currentHostel: hostel});

    setRegistrationCode = registrationCode => this.setState({registrationCode: registrationCode});


    render() {
        if (this.props.loadingUser) return spinLoader();

        return (
            <BrowserRouter>
                <Routes {...this.props}
                        {...this.state}
                        setRegistrationCode={this.setRegistrationCode}
                        setCurrentHostel={this.setCurrentHostel}
                        setGuestCountry={this.setGuestCountry}/>
            </BrowserRouter>
        )
    }
}

export default withAuthentication(App);
