import React, {Component} from "react";
import {firestoreHostelPos, hostelPosUrl} from "../../firebase";
import {countries} from "../../utils";
import {Form, Icon, Input, Modal, Radio, Select, Tooltip} from "antd";
import {doSignOut} from "../../firebase/auth";
import withAuthorization from "../../session/withAuthorization";

const visitor = Form.create()(
    class Visitor extends Component {

        state = {
            guestFirstName: "",
            guestLastName: "",
            guestCountry: "",
            guestGender: "",
            guestAge: "",
            guestEmail: "",
            guestPhone: "",
            note: "",
            guestDocumentNumber: "",
            documentVisitor: this.props.match.params.documentVisitor,
        };

        componentDidMount() {
            this.state.documentVisitor !== "foreigner" && this.fetchVisitor(this.state.documentVisitor);
        };

        fetchVisitor = async document => {
            const response = await fetch(`${hostelPosUrl}/visitors/?document=${document}`, {method: "GET"});
            if (!response.ok) return;

            const json = await response.json();
            console.log(json);
            const visitor = json.visitor;
            visitor && this.visitorToState(visitor);
        };

        cleanForm = () => {
            this.setState({
                guestFirstName: "",
                guestLastName: "",
                guestCountry: "",
                guestGender: "",
                guestAge: "",
                guestEmail: "",
                guestDocumentNumber: "",
                guestPhone: "",
                note: "",
                photo: "",
            });
        };

        postVisitor = async _ => await fetch(`${hostelPosUrl}/visitors`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                visitor: this.stateToVisitor(),
                hostelId: this.props.currentUser.hostel.id
            })
        });

        sendForm = async () => {
            const response = await this.postVisitor();

            if (!response.ok) {
                this.props.setRegistrationCode("TRY AGAIN");
                this.cleanForm();
                return;
            }

            const registrationCode = await response.text();

            this.props.setRegistrationCode(registrationCode);

            this.cleanForm();
        };

        stateToVisitor = () => ({
            createAt: new Date(),
            guestFirstName: this.state.guestFirstName,
            guestLastName: this.state.guestLastName,
            guestCountry: this.guestCountry(),
            guestGender: this.state.guestGender,
            guestAge: this.state.guestAge,
            guestEmail: this.state.guestEmail,
            hostelId: this.props.currentUser.hostel.id,
            guestDocumentNumber: this.guestDocumentNumber(),
            guestPhone: this.state.guestPhone,
            note: this.state.note || "",
            photo: this.state.photo || "",
            isVisitor: true,
            isStaff: false
        });

        guestCountry = () => {
            if (this.state.documentVisitor === "foreigner") {
                return this.state.guestCountry;
            } else {
                const currentUserNationality = this.props.currentUser.nationality === "peruvian" ? "Peru" : "Bolivia";

                return countries.find(country => country.countryName === currentUserNationality).value;
            }
        };

        guestDocumentNumber = () => this.state.documentVisitor !== "foreigner" ? this.state.documentVisitor : "";

        visitorToState = (visitor) => {
            this.setState({
                guestFirstName: visitor.guestFirstName,
                guestLastName: visitor.guestLastName,
                guestGender: visitor.guestGender,
                guestEmail: visitor.guestEmail,
                guestPhone: visitor.guestPhone,
                guestCountry: visitor.guestCountry,
                note: visitor.note || "",
                photo: visitor.photo || "",
            });
        };

        addVisitor = (event) => {
            event.preventDefault();

            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.props.setRegistrationCode("");
                    this.sendForm();
                    this.props.history.push("/registration-code");
                }
            });
        };

        logOut = () => {
            Modal.confirm({
                title: "Are you sure you want to logout?",
                okText: "YES",
                cancelText: "NO",
                onOk() {
                    doSignOut();
                },
            });
        };

        goBack = () => {
            const url = this.state.documentVisitor === "foreigner" ? "/countries" : "/documents";

            this.props.history.push(url);
        };

        render() {
            const {getFieldDecorator} = this.props.form;

            return (
                <div id="visitors">
                    <div className="header-visitors">
                        <Tooltip
                            placement="bottom"
                            title="Go back">
                        <span
                            className="header-back"
                            onClick={() => this.goBack()}>
                            <Icon type="left-circle"/>
                        </span>
                        </Tooltip>
                        <Tooltip
                            placement="bottom"
                            title="Logout">
                        <span
                            onClick={() => this.logOut()}
                            className="header-logout">
                            <Icon type="poweroff"/>
                        </span>
                        </Tooltip>
                    </div>
                    <h1 className="page-title">{this.props.currentUser.hostel.hostelName.toUpperCase()}</h1>
                    <div className="page-container">
                        <Form
                            autoComplete="off"
                            className="form-visitor"
                            onSubmit={this.addVisitor}>
                            <div className="page-form">
                                <Form.Item label="First name:">
                                    {getFieldDecorator("guestFirstName", {
                                        initialValue: this.state.guestFirstName,
                                        rules: [{required: true, message: "First name is required."}],
                                    })(
                                        <Input
                                            type="text"
                                            placeholder="First name"
                                            name="guestFirstName"
                                            id="guestFirstName"
                                            autoFocus
                                            onChange={e => this.setState({guestFirstName: e.target.value})}/>
                                    )}
                                </Form.Item>
                                <Form.Item label="Last name:">
                                    {getFieldDecorator("guestLastName", {
                                        initialValue: this.state.guestLastName,
                                        rules: [{required: true, message: "Last name is required."}],
                                    })(
                                        <Input
                                            name="guest-last-name"
                                            onChange={value => this.setState({guestLastName: value.target.value})}
                                            placeholder="Last Name"
                                            type="text"/>
                                    )}
                                </Form.Item>
                                <Form.Item label="Gender:">
                                    {getFieldDecorator("guestGender", {
                                        initialValue: this.state.guestGender,
                                        rules: [{required: true, message: "Gender is required."}],
                                    })(
                                        <Radio.Group onChange={value => this.setState({guestGender: value.target.value})}>
                                            <Radio value="M">Male</Radio>
                                            <Radio value="F">Female</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                {
                                    this.state.documentVisitor !== "foreigner" &&
                                    <Form.Item label="Phone:">
                                        {getFieldDecorator("guestPhone", {
                                            initialValue: this.state.guestPhone,
                                            rules: [{required: true, message: "Phone is required."}],
                                        })(
                                            <Input
                                                onChange={value => this.setState({guestPhone: value.target.value})}
                                                placeholder="Phone"
                                                type="text"/>
                                        )}
                                    </Form.Item>
                                }
                                <Form.Item label="Email:">
                                    {getFieldDecorator("guestEmail", {
                                        initialValue: this.state.guestEmail,
                                        rules: [{required: true, message: "Email is required."}],
                                    })(
                                        <Input
                                            onChange={value => this.setState({guestEmail: value.target.value})}
                                            placeholder="Email"
                                            type="email"/>
                                    )}
                                </Form.Item>
                                {
                                    this.state.documentVisitor === "foreigner" &&
                                    <Form.Item label="Country">
                                        {getFieldDecorator("guestCountry", {
                                            initialValue: this.state.guestCountry || undefined,
                                            rules: [{required: true, message: "Country is required."}],
                                        })(
                                            <Select
                                                className="select-full-width"
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                onChange={(value) => this.setState({guestCountry: value})}
                                                optionFilterProp="children"
                                                placeholder="Country"
                                                showSearch>
                                                {
                                                    countries.map(country =>
                                                        <Select.Option key={country.value}
                                                                       value={country.value}>{country.countryName}</Select.Option>)
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                }
                                <Input
                                    className="button--register"
                                    style={{height: "auto"}}
                                    type="submit"
                                    value="REGISTER"/>
                                <div className="small-remark">
                                    <small>
                                        * Al registrase, usted brinda la aceptación de que sus comprobantes de pago sean
                                        enviados mediante correo electrónico a la dirección email consignada en
                                        conformidad con la legislación tributaria vigente.
                                    </small>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            )
        }
    }
);

const condition = authUser => authUser && !authUser.isAdmin;
const redirect = () => "/users";
export default withAuthorization(condition, redirect)(visitor);
