import React, {Component} from "react";
import {Route} from "react-router-dom";
import AuthUserContext from "../session/AuthUserContext";
import {Redirect} from "react-router";

export default class PrivateRoute extends Component {

    childWithProperties = (props) => React.Children.map(this.props.children, child => React.cloneElement(child, {...props}));

    render() {

        return (
            <AuthUserContext.Consumer>
                {
                    authUser => authUser
                        ? <Route path={this.props.path}
                                 render={props => this.childWithProperties(props)}/>
                        : <Redirect to={"/login"}/>
                }
            </AuthUserContext.Consumer>
        )
    }
}
