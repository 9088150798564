import React, {Component} from "react";
import {Button, Icon, List, Modal, Tooltip} from "antd";
import {deleteDocument} from "../../firebase";
import {orderBy} from "lodash";
import withAuthorization from "../../session/withAuthorization";

const users = class Users extends Component {

    deleteUser = (user) => {
        Modal.confirm({
            title: "Are you sure delete?",
            content: "All data with connection to this item deleted.",
            okText: "YES",
            okType: "danger",
            cancelText: "NO",
            onOk: () => {
                deleteDocument("users", user.id);
            },
        })
    };

    render() {

        let {users} = this.props;

        return (
            <div>
                <div>
                    <span style={{fontSize: "25px"}}>
                    <Icon type="team"/>Users</span>
                </div>
                <div style={{textAlign: "center", marginTop: "25px", marginBottom: "15px"}}>
                    <Button
                        type="primary"
                        icon="plus-circle"
                        className="square-button"
                        onClick={() => {
                            this.props.history.push("/users/new")
                        }}>
                        ADD USER
                    </Button>
                </div>
                <hr/>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={orderBy(users, user => user.email, ['email'], ['asc'])}
                    renderItem={user => (
                        <List.Item style={{cursor: "pointer", display: "flex"}}
                                   actions={
                                       [
                                           <Tooltip placement={"top"}
                                                    title={"Delete user"}>
                                               <div onClick={() => this.deleteUser(user)}>
                                                   <Icon style={{color: '#fe008f', fontSize: '24px',}}
                                                         type="delete"/>
                                               </div>
                                           </Tooltip>
                                       ]}>
                            <div>
                                <h3 style={{margin: '0px'}}
                                    onClick={() => this.props.history.push(`/users/${user.id}`)}>
                                    <div style={{color: "#40a9ff"}}>
                                        {user.email}
                                    </div>
                                </h3>
                                <span>
                                    <b>
                                        {!user.isAdmin && this.props.hostels.length > 0 && (this.props.hostels.find(hostel => hostel.id === user.hostelId).hostelName)}
                                    </b>
                                </span>
                            </div>
                        </List.Item>
                    )}>
                </List>
            </div>
        );
    }
};

const condition = authUser => authUser && authUser.isAdmin;
const redirect = () => "/";
export default withAuthorization(condition, redirect)(users);