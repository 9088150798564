import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const hostName = window.location.hostname;
let hostelPosUrl;

const hotelPosIamProductionConfig = {
    apiKey: "AIzaSyBXXJ2Apuxb13BnhIvLujjO21eEVN5VyWo",
    authDomain: "hostel-pos-iam-213419.firebaseapp.com",
    projectId: "hostel-pos-iam-213419",
};

const hotelPosIamDevelopmentConfig = {
    apiKey: "AIzaSyDdcVdmg87PiojGqdwIXUuf9XaJXqDNGRo",
    authDomain: "hostel-pos-iam-dev.firebaseapp.com",
    projectId: "hostel-pos-iam-dev"
};

if (hostName !== "localhost" && !hostName.includes("-dev")) {
    firebase.initializeApp(hotelPosIamProductionConfig, "hostelPosIam");
    hostelPosUrl = "https://us-central1-hostel-pos.cloudfunctions.net/api";
    console.log("prod");
} else {
    firebase.initializeApp(hotelPosIamDevelopmentConfig, "hostelPosIam");
    hostelPosUrl = "https://us-central1-hostel-pos-dev.cloudfunctions.net/api";
    console.log("dev");
}

let pageLoaded = false;

firebase.app("hostelPosIam").firestore()
    .collection("versions")
    .onSnapshot(() => {
        pageLoaded && document.location.reload(true);
        pageLoaded = true;
    });

const firestoreHostelPosIam = firebase.app("hostelPosIam").firestore();
const authHostelPosIam = firebase.app("hostelPosIam").auth();

export {
    hostelPosUrl,
    firestoreHostelPosIam,
    authHostelPosIam
};
