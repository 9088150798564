import React, {Component} from "react";
import {Icon, Input, Modal, Tooltip} from "antd";
import {doSignOut} from "../../firebase/auth";
import withAuthorization from "../../session/withAuthorization";

const countries = class Countries extends Component {

    clickForeigner = () => {
        this.props.setGuestCountry("Foreigner");
        this.props.history.push("/visitors/foreigner");
    };

    clickNationality = () => {
        this.props.setGuestCountry(this.props.currentUser.nationality === "peruvian" ? "Peru" : "Bolivia");
        this.props.history.push("/documents")
    };

    logOut = () => {
        Modal.confirm({
            title: 'Are you sure you want to logout?',
            okText: 'YES',
            cancelText: 'NO',
            onOk() {
                doSignOut();
            },
        });
    };

    render() {
        return (
            <div id="countries">
                <div className="header-visitors">
                    <Tooltip
                        placement="bottom"
                        title="Go back">
                        <span
                            className="header-back"
                            style={{visibility: "hidden"}}>
                            <Icon type="left-circle"/>
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title="Logout">
                        <span
                            onClick={() => this.logOut()}
                            className="header-logout">
                            <Icon type="poweroff"/>
                        </span>
                    </Tooltip>
                </div>
                <h1 className="page-title">NATIONALITY:</h1>
                <div className="page-container">
                    <div className="page-form">
                        <Input
                            className="input-to-button"
                            onClick={() => this.clickNationality()}
                            type="submit"
                            value={this.props.currentUser.nationality.toUpperCase()}/>
                        <br/>
                        <Input
                            className="input-to-button"
                            type="submit"
                            onClick={() => this.clickForeigner()}
                            value="FOREIGNER"/>
                    </div>
                </div>
            </div>
        )
    }

};

const condition = authUser => authUser && !authUser.isAdmin;
const redirect = () => "/users";
export default withAuthorization(condition, redirect)(countries);