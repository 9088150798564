import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from "@fortawesome/free-solid-svg-icons"

class RegistrationCode extends Component {

    cleanAndReset = () => {
        this.props.history.push("/");
        this.props.setRegistrationCode("");
    };

    render() {
        return (
            <div className="page body">
                <div className="container">
                    <div className="message-wrapper">
                        <h2 className="hostel">{this.props.currentUser.hostel.hostelName.toUpperCase()}</h2>
                        {
                            this.props.registrationCode ?
                                <h1 className="code">{this.props.registrationCode}</h1>
                                : <FontAwesomeIcon
                                    className="loading rotate"
                                    icon={faSpinner}/>
                        }
                        <h2 className="note">PLEASE CALL THE STAFF TO WRITE DOWN YOUR CODE ON YOUR WRISTBAND</h2>
                        <a className="button button--finish"
                           onClick={() => this.cleanAndReset()}>
                            FINISH
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistrationCode;
