import React, {Component} from "react";

export default class UpdateVersion extends Component {

    render() {
        return (
            <div className="page-update body">
                <div className="container update-page">
                    <h1>
                        Please refresh to get the latest version of the application.
                    </h1>
                    <button variant="raised"
                            color="primary"
                            onClick={() => document.location.reload(true)}>
                        REFRESH
                    </button>
                </div>
            </div>
        );
    }
}