import React, {Component} from "react";
import {Icon, Layout, Menu, Modal, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {doSignOut} from "../firebase/auth";

const {Header, Sider, Content} = Layout;
const confirm = Modal.confirm;

export default class BaseLayout extends Component {

    state = {
        collapsed: true,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    logOut = () => {
        confirm({
            title: 'Are you sure you want to logout?',
            okText: 'YES',
            cancelText: 'NO',
            onOk() {
                doSignOut();
            },
        });
    };

    childWithProperties = (props) => React.Children.map(this.props.children, child => React.cloneElement(child, {...props}));

    render() {

        let route = window.location.href;

        let currentKey = route.split("/").pop();

        return (
            <Layout className="base-layout">
                <div>
                    <Layout className="base-layout">
                        <Sider
                            trigger={null}
                            collapsedWidth={0}
                            collapsible
                            collapsed={this.state.collapsed}>
                            <div className="logoVersion">V {this.props.version}</div>
                            <Menu theme="dark"
                                  mode="inline"
                                  defaultSelectedKeys={[currentKey]}>
                                <Menu.Item key="users">
                                    <Link to="/users">
                                        <Icon type="team"/>
                                        <span>Users</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/login"
                                           onClick={() => this.logOut()}>
                                    <Link to="/login">
                                        <Icon type="poweroff"/>
                                        <span>Log Out</span>
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout>
                            <Header style={{background: '#fff', padding: 0}}>
                                <Icon
                                    className="trigger"
                                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                    onClick={this.toggle}/>
                                <span className="projectTitle">
                                                        {this.props.currentUser.hostel.hostelName}
                                                    </span>
                                <Tooltip placement="bottom" title={"Logout"}>
                                                    <span style={{cursor: "pointer", float: "right"}}
                                                          onClick={() => this.logOut()}>
                                                        <Icon
                                                            style={{
                                                                marginTop: "5px",
                                                                fontSize: "25px"
                                                            }}
                                                            className="trigger"
                                                            type={"poweroff"}/>
                                                    </span>
                                </Tooltip>
                            </Header>
                            <Content style={{
                                margin: '24px 16px',
                                padding: 24,
                                background: '#fff',
                                minHeight: '86vh'
                            }}>
                                {this.childWithProperties(this.props)}
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </Layout>
        )
    }
};