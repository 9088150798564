export const countries = [
    {value: "AF", countryName: "Afghanistan"},
    {value: "AX", countryName: "Åland Islands"},
    {value: "AL", countryName: "Albania"},
    {value: "DZ", countryName: "Algeria"},
    {value: "AS", countryName: "American Samoa"},
    {value: "AD", countryName: "Andorra"},
    {value: "AO", countryName: "Angola"},
    {value: "AI", countryName: "Anguilla"},
    {value: "AQ", countryName: "Antarctica"},
    {value: "AG", countryName: "Antigua and Barbuda"},
    {value: "AR", countryName: "Argentina"},
    {value: "AM", countryName: "Armenia"},
    {value: "AW", countryName: "Aruba"},
    {value: "AU", countryName: "Australia"},
    {value: "AT", countryName: "Austria"},
    {value: "AZ", countryName: "Azerbaijan"},
    {value: "BS", countryName: "Bahamas"},
    {value: "BH", countryName: "Bahrain"},
    {value: "BD", countryName: "Bangladesh"},
    {value: "BB", countryName: "Barbados"},
    {value: "BY", countryName: "Belarus"},
    {value: "BE", countryName: "Belgium"},
    {value: "BZ", countryName: "Belize"},
    {value: "BJ", countryName: "Benin"},
    {value: "BM", countryName: "Bermuda"},
    {value: "BT", countryName: "Bhutan"},
    {value: "BO", countryName: "Bolivia"},
    {value: "BQ", countryName: "Bonaire, Sint Eustatius and Saba"},
    {value: "BA", countryName: "Bosnia and Herzegovina"},
    {value: "BW", countryName: "Botswana"},
    {value: "BV", countryName: "Bouvet Island"},
    {value: "BR", countryName: "Brazil"},
    {value: "IO", countryName: "British Indian Ocean Territory"},
    {value: "BN", countryName: "Brunei Darussalam"},
    {value: "BG", countryName: "Bulgaria"},
    {value: "BF", countryName: "Burkina Faso"},
    {value: "BI", countryName: "Burundi"},
    {value: "KH", countryName: "Cambodia"},
    {value: "CM", countryName: "Cameroon"},
    {value: "CA", countryName: "Canada"},
    {value: "CV", countryName: "Cape Verde"},
    {value: "KY", countryName: "Cayman Islands"},
    {value: "CF", countryName: "Central African Republic"},
    {value: "TD", countryName: "Chad"},
    {value: "CL", countryName: "Chile"},
    {value: "CN", countryName: "China"},
    {value: "CX", countryName: "Christmas Island"},
    {value: "CC", countryName: "Cocos (Keeling) Islands"},
    {value: "CO", countryName: "Colombia"},
    {value: "KM", countryName: "Comoros"},
    {value: "CG", countryName: "Congo, Republic of the (Brazzaville)"},
    {value: "CD", countryName: "Congo, the Democratic Republic of the (Kinshasa)"},
    {value: "CK", countryName: "Cook Islands"},
    {value: "CR", countryName: "Costa Rica"},
    {value: "CI", countryName: "Côte d'Ivoire, Republic of"},
    {value: "HR", countryName: "Croatia"},
    {value: "CU", countryName: "Cuba"},
    {value: "CW", countryName: "Curaçao"},
    {value: "CY", countryName: "Cyprus"},
    {value: "CZ", countryName: "Czech Republic"},
    {value: "DK", countryName: "Denmark"},
    {value: "DJ", countryName: "Djibouti"},
    {value: "DM", countryName: "Dominica"},
    {value: "DO", countryName: "Dominican Republic"},
    {value: "EC", countryName: "Ecuador"},
    {value: "EG", countryName: "Egypt"},
    {value: "SV", countryName: "El Salvador"},
    {value: "GQ", countryName: "Equatorial Guinea"},
    {value: "ER", countryName: "Eritrea"},
    {value: "EE", countryName: "Estonia"},
    {value: "ET", countryName: "Ethiopia"},
    {value: "FK", countryName: "Falkland Islands (Islas Malvinas)"},
    {value: "FO", countryName: "Faroe Islands"},
    {value: "FJ", countryName: "Fiji"},
    {value: "FI", countryName: "Finland"},
    {value: "FR", countryName: "France"},
    {value: "GF", countryName: "French Guiana"},
    {value: "PF", countryName: "French Polynesia"},
    {value: "TF", countryName: "French Southern and Antarctic Lands"},
    {value: "GA", countryName: "Gabon"},
    {value: "GM", countryName: "Gambia, The"},
    {value: "GE", countryName: "Georgia"},
    {value: "DE", countryName: "Germany"},
    {value: "GH", countryName: "Ghana"},
    {value: "GI", countryName: "Gibraltar"},
    {value: "GR", countryName: "Greece"},
    {value: "GL", countryName: "Greenland"},
    {value: "GD", countryName: "Grenada"},
    {value: "GP", countryName: "Guadeloupe"},
    {value: "GU", countryName: "Guam"},
    {value: "GT", countryName: "Guatemala"},
    {value: "GG", countryName: "Guernsey"},
    {value: "GN", countryName: "Guinea"},
    {value: "GW", countryName: "Guinea-Bissau"},
    {value: "GY", countryName: "Guyana"},
    {value: "HT", countryName: "Haiti"},
    {value: "HM", countryName: "Heard Island and McDonald Islands"},
    {value: "VA", countryName: "Holy See (Vatican City)"},
    {value: "HN", countryName: "Honduras"},
    {value: "HK", countryName: "Hong Kong"},
    {value: "HU", countryName: "Hungary"},
    {value: "IS", countryName: "Iceland"},
    {value: "IN", countryName: "India"},
    {value: "ID", countryName: "Indonesia"},
    {value: "IR", countryName: "Iran, Islamic Republic of"},
    {value: "IQ", countryName: "Iraq"},
    {value: "IE", countryName: "Ireland"},
    {value: "IM", countryName: "Isle of Man"},
    {value: "IL", countryName: "Israel"},
    {value: "IT", countryName: "Italy"},
    {value: "JM", countryName: "Jamaica"},
    {value: "JP", countryName: "Japan"},
    {value: "JE", countryName: "Jersey"},
    {value: "JO", countryName: "Jordan"},
    {value: "KZ", countryName: "Kazakhstan"},
    {value: "KE", countryName: "Kenya"},
    {value: "KI", countryName: "Kiribati"},
    {value: "KP", countryName: "Korea, Democratic People's Republic of"},
    {value: "KR", countryName: "Korea, Republic of"},
    {value: "KW", countryName: "Kuwait"},
    {value: "KG", countryName: "Kyrgyzstan"},
    {value: "LA", countryName: "Laos"},
    {value: "LV", countryName: "Latvia"},
    {value: "LB", countryName: "Lebanon"},
    {value: "LS", countryName: "Lesotho"},
    {value: "LR", countryName: "Liberia"},
    {value: "LY", countryName: "Libya"},
    {value: "LI", countryName: "Liechtenstein"},
    {value: "LT", countryName: "Lithuania"},
    {value: "LU", countryName: "Luxembourg"},
    {value: "MO", countryName: "Macao"},
    {value: "MK", countryName: "Macedonia, Republic of"},
    {value: "MG", countryName: "Madagascar"},
    {value: "MW", countryName: "Malawi"},
    {value: "MY", countryName: "Malaysia"},
    {value: "MV", countryName: "Maldives"},
    {value: "ML", countryName: "Mali"},
    {value: "MT", countryName: "Malta"},
    {value: "MH", countryName: "Marshall Islands"},
    {value: "MQ", countryName: "Martinique"},
    {value: "MR", countryName: "Mauritania"},
    {value: "MU", countryName: "Mauritius"},
    {value: "YT", countryName: "Mayotte"},
    {value: "MX", countryName: "Mexico"},
    {value: "FM", countryName: "Micronesia, Federated States of"},
    {value: "MD", countryName: "Moldova"},
    {value: "MC", countryName: "Monaco"},
    {value: "MN", countryName: "Mongolia"},
    {value: "ME", countryName: "Montenegro"},
    {value: "MS", countryName: "Montserrat"},
    {value: "MA", countryName: "Morocco"},
    {value: "MZ", countryName: "Mozambique"},
    {value: "MM", countryName: "Myanmar"},
    {value: "NA", countryName: "Namibia"},
    {value: "NR", countryName: "Nauru"},
    {value: "NP", countryName: "Nepal"},
    {value: "NL", countryName: "Netherlands"},
    {value: "NC", countryName: "New Caledonia"},
    {value: "NZ", countryName: "New Zealand"},
    {value: "NI", countryName: "Nicaragua"},
    {value: "NE", countryName: "Niger"},
    {value: "NG", countryName: "Nigeria"},
    {value: "NU", countryName: "Niue"},
    {value: "NF", countryName: "Norfolk Island"},
    {value: "MP", countryName: "Northern Mariana Islands"},
    {value: "NO", countryName: "Norway"},
    {value: "OM", countryName: "Oman"},
    {value: "PK", countryName: "Pakistan"},
    {value: "PW", countryName: "Palau"},
    {value: "PS", countryName: "Palestine, State of"},
    {value: "PA", countryName: "Panama"},
    {value: "PG", countryName: "Papua New Guinea"},
    {value: "PY", countryName: "Paraguay"},
    {value: "PE", countryName: "Peru"},
    {value: "PH", countryName: "Philippines"},
    {value: "PN", countryName: "Pitcairn"},
    {value: "PL", countryName: "Poland"},
    {value: "PT", countryName: "Portugal"},
    {value: "PR", countryName: "Puerto Rico"},
    {value: "QA", countryName: "Qatar"},
    {value: "RE", countryName: "Réunion"},
    {value: "RO", countryName: "Romania"},
    {value: "RU", countryName: "Russian Federation"},
    {value: "RW", countryName: "Rwanda"},
    {value: "BL", countryName: "Saint Barthélemy"},
    {value: "SH", countryName: "Saint Helena, Ascension and Tristan da Cunha"},
    {value: "KN", countryName: "Saint Kitts and Nevis"},
    {value: "LC", countryName: "Saint Lucia"},
    {value: "MF", countryName: "Saint Martin"},
    {value: "PM", countryName: "Saint Pierre and Miquelon"},
    {value: "VC", countryName: "Saint Vincent and the Grenadines"},
    {value: "WS", countryName: "Samoa"},
    {value: "SM", countryName: "San Marino"},
    {value: "ST", countryName: "Sao Tome and Principe"},
    {value: "SA", countryName: "Saudi Arabia"},
    {value: "SN", countryName: "Senegal"},
    {value: "RS", countryName: "Serbia"},
    {value: "SC", countryName: "Seychelles"},
    {value: "SL", countryName: "Sierra Leone"},
    {value: "SG", countryName: "Singapore"},
    {value: "SX", countryName: "Sint Maarten (Dutch part)"},
    {value: "SK", countryName: "Slovakia"},
    {value: "SI", countryName: "Slovenia"},
    {value: "SB", countryName: "Solomon Islands"},
    {value: "SO", countryName: "Somalia"},
    {value: "ZA", countryName: "South Africa"},
    {value: "GS", countryName: "South Georgia and South Sandwich Islands"},
    {value: "SS", countryName: "South Sudan"},
    {value: "ES", countryName: "Spain"},
    {value: "LK", countryName: "Sri Lanka"},
    {value: "SD", countryName: "Sudan"},
    {value: "SR", countryName: "Suriname"},
    {value: "SZ", countryName: "Swaziland"},
    {value: "SE", countryName: "Sweden"},
    {value: "CH", countryName: "Switzerland"},
    {value: "SY", countryName: "Syrian Arab Republic"},
    {value: "TW", countryName: "Taiwan"},
    {value: "TJ", countryName: "Tajikistan"},
    {value: "TZ", countryName: "Tanzania, United Republic of"},
    {value: "TH", countryName: "Thailand"},
    {value: "TL", countryName: "Timor-Leste"},
    {value: "TG", countryName: "Togo"},
    {value: "TK", countryName: "Tokelau"},
    {value: "TO", countryName: "Tonga"},
    {value: "TT", countryName: "Trinidad and Tobago"},
    {value: "TN", countryName: "Tunisia"},
    {value: "TR", countryName: "Turkey"},
    {value: "TM", countryName: "Turkmenistan"},
    {value: "TC", countryName: "Turks and Caicos Islands"},
    {value: "TV", countryName: "Tuvalu"},
    {value: "UG", countryName: "Uganda"},
    {value: "UA", countryName: "Ukraine"},
    {value: "AE", countryName: "United Arab Emirates"},
    {value: "GB", countryName: "United Kingdom"},
    {value: "US", countryName: "United States"},
    {value: "UM", countryName: "United States Minor Outlying Islands"},
    {value: "UY", countryName: "Uruguay"},
    {value: "UZ", countryName: "Uzbekistan"},
    {value: "VU", countryName: "Vanuatu"},
    {value: "VE", countryName: "Venezuela, Bolivarian Republic of"},
    {value: "VN", countryName: "Vietnam"},
    {value: "VG", countryName: "Virgin Islands, British"},
    {value: "VI", countryName: "Virgin Islands, U.S."},
    {value: "WF", countryName: "Wallis and Futuna"},
    {value: "EH", countryName: "Western Sahara"},
    {value: "YE", countryName: "Yemen"},
    {value: "ZM", countryName: "Zambia"},
    {value: "ZW", countryName: "Zimbabwe"},
];