import React, {Component} from "react";
import {Form, Icon, Input, Modal, Tooltip} from "antd";
import {doSignOut} from "../../firebase/auth";
import withAuthorization from "../../session/withAuthorization";

const documents = Form.create()(
    class Documents extends Component {

        state = {
            documentNumber: ""
        };

        registerDocumentNumber = (event) => {
            event.preventDefault();

            this.props.form.validateFields((err) => {
                if (!err) {
                    this.props.history.push(`/visitors/${this.state.documentNumber}`)
                }
            });
        };

        logOut = () => {
            Modal.confirm({
                title: "Are you sure you want to logout?",
                okText: "YES",
                cancelText: "NO",
                onOk() {
                    doSignOut();
                },
            });
        };

        render() {
            const {getFieldDecorator} = this.props.form;

            return (
                <div id="documents">
                    <div className="header-visitors">
                        <Tooltip
                            placement="bottom"
                            title="Go back">
                        <span
                            className="header-back"
                            onClick={() => this.props.history.push("/countries")}>
                            <Icon type="left-circle"/>
                        </span>
                        </Tooltip>
                        <Tooltip
                            placement="bottom"
                            title="Logout">
                        <span
                            onClick={() => this.logOut()}
                            className="header-logout">
                            <Icon type="poweroff"/>
                        </span>
                        </Tooltip>
                    </div>
                    <h1 className="page-title">{this.props.currentUser.documentType.toUpperCase()}:</h1>
                    <div className="page-container">
                        <Form
                            className="page-form"
                            onSubmit={this.registerDocumentNumber}>
                            <Form.Item label={this.props.currentUser.documentType.toUpperCase()}>
                                {
                                    getFieldDecorator("documentNumber", {
                                        initialValue: this.state.documentNumber,
                                        rules: [
                                            {required: true, message: "Document type is required."},
                                        ],
                                    })(
                                        <Input
                                            type="number"
                                            autoFocus
                                            placeholder={this.props.currentUser.documentType.toUpperCase()}
                                            onChange={(value) => this.setState({documentNumber: value.target.value})}/>
                                    )
                                }
                            </Form.Item>
                            <Input
                                className="input-to-button"
                                type="submit"
                                value="NEXT"/>
                        </Form>
                    </div>
                </div>
            )
        }

    }
    )
;

const condition = authUser => authUser && !authUser.isAdmin;
const redirect = () => "/users";
export default withAuthorization(condition, redirect)(documents);