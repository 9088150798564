import React from 'react';
import AuthUserContext from './AuthUserContext';
import {authHostelPosIam, firestoreHostelPosIam, hostelPosUrl} from "../firebase/firebase";

const withAuthentication = (Component) =>
    class WithAuthentication extends React.Component {

        state = {
            authUser: null,
            loadingUser: true
        };

        componentDidMount() {
            authHostelPosIam.onAuthStateChanged(async authUser => {
                authUser
                    ? await this.fetchUser(authUser)
                    : this.setState(() => ({
                        authUser: null,
                        loadingUser: false,
                    }));
            });
        };

        userToState = (user) => {
            this.setState({
                authUser: user,
                loadingUser: false
            });
        };

        fetchUserHostel = async (user) => {
            if (user.hostelId) {
                const response = await this.fetchHostels();
                const hostels = (await response.json()).hostels;

                user.hostel = hostels.find(hostel => hostel.id === user.hostelId) || {};

            }
            this.userToState(user);
        };

        fetchHostels = async _ => await fetch(`${hostelPosUrl}/hostels`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });

        fetchUser = async authUser => {
            const snapshot = await firestoreHostelPosIam.collection("users").doc(authUser.uid).get();
            const user = snapshot.data();
            user.id = authUser.uid;

            await this.fetchUserHostel(user);
        };

        render() {
            const {authUser} = this.state;

            return (
                <AuthUserContext.Provider value={authUser}>
                    <Component
                        loadingUser={this.state.loadingUser}
                        currentUser={this.state.authUser}
                        version={this.props.version}/>
                </AuthUserContext.Provider>
            );
        }
    };

export default withAuthentication;
